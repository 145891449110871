import { BrowserRouter, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import './assets/css/style.css'

import Seller from './Components/sellerr/sellerr/Seller';
import SpecialSellerDetails from './Components/sellerr/sellerr/specialSellerDetails/SpecialSellerDetails';


function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage  />} />
          <Route path="special-seller-sign-up" element={<Seller />} />
          <Route path="special-seller/details/:applicationNo" element={<SpecialSellerDetails />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
