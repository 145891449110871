
import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import { useParams } from "react-router-dom"

import html2canvas from 'html2canvas'
import jsPDF from "jspdf";
import { useRef } from "react"
import { base_url } from "../../../../server"

function SpecialSellerDetails() {
    const param = useParams()
    // console.log(param);
    const pdfRef = useRef()
    const [sellerDetaials, setSellerDetails] = useState(null)
    console.log(sellerDetaials);


    const getSellerDetaials = async () => {
        try {
            const res = await axios.post(`${base_url}specialSeller/public/track`, { applicationNo: param.applicationNo })
            setSellerDetails(res.data.data[0])

        } catch (error) {

        }
    }


    const downloadPdf = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('img/png')
            const pdf = new jsPDF('p', 'mm', 'a4', true)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()
            const imgWidth = canvas.width
            const imgHeight = canvas.height
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
            const imgX = (pdfWidth - imgWidth * ratio) / 2
            const imgY = 30
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio)
            pdf.save('invoice.pdf')
        })
    }

    useEffect(() => {
        getSellerDetaials()
    }, [])
    return (
        <>
            <div className="details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="details-containt">
                                <div ref={pdfRef}>
                                    <h4>Dear Applicant</h4>
                                    <p>Your First Name: {sellerDetaials?.bene_firstname} </p>
                                    <p>Your Last Name: {sellerDetaials?.bene_lastname}</p>
                                    <p>Your Registration No: {sellerDetaials?.applicationNo}</p>
                                    {/* <p>MSH-1001</p> */}
                                    <p>Dated:{sellerDetaials?.applicationDate}</p>
                                    <p>Is sucessfully submitted, current Status: <span style={{ color: 'red' }}>{sellerDetaials?.status[0]?.name}</span></p>
                                    <p>MSHFDC Team</p>
                                </div>
                                <div className="button">
                                    <button style={{ border: 'none', padding: '5px', borderRadius: "5px", background: 'aqua' }} onClick={downloadPdf}>save PDF</button>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SpecialSellerDetails