import { Box, Button, List, TextField } from '@mui/material'
import { NavLink } from "react-router-dom";
import imgeLogo from '../assets/imgLogo.jpeg'
import '../stylecss/navbarStyle.css'
import { MdOutlineArrowDropDown } from 'react-icons/md';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import imglogo1 from '../assets/logo.jpeg'



const LandingNavbar = (props) => {
    

    return (
        <>
            <Box>
                <Box className='navTop-box'>
                    <List className={`toplist-div ${props.colorMode === 'black' ? 'purpal' : props.colorMode === 'white' ? 'geen' : 'orange'}`}>
                        <div className=' d-flex'>
                            <li className='d-flex mt-3 ms-3'>
                                <NavLink style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }} >Text Size</NavLink>
                                <div className='fontsizebtn d-flex ms-2'>
                                    <p className='p-tag-btn1' onClick={props.originalFontSize}>A</p>
                                    <p className='p-tag-btn2' onClick={props.FontSizeTwenty}>A</p>
                                    <p className='p-tag-btn3' onClick={props.IncreaseFontSize}>A</p>
                                </div>
                            </li>
                            <li className='ms-5 d-flex mt-3'>
                                <NavLink style={{ color: 'white', textDecoration: 'none', fontWeight: 'bold' }}>Theme</NavLink>
                                <div className='colorchangebtn d-flex ms-2'>
                                    <p className='ptag-color1' onClick={() => {
                                        props.colorThemeSelector("purple");
                                    }}>
                                    </p>
                                    <p className='ptag-color2' onClick={() => {
                                        props.colorThemeSelector("green");
                                    }}>
                                    </p>
                                    <p className='ptag-color3' onClick={() => {
                                        props.colorThemeSelector("orange");
                                    }}>
                                    </p>
                                </div>

                            </li>
                        </div>

                        <div className='ms-3'>
                            <li className='nav-list'>
                                <NavLink>Helpline No.: +918035742016</NavLink>
                            </li>

                            <li className='nav-list ms-5 ps-3'>
                                <NavLink>Helpline Email.: mshfdc@rediffmail.com</NavLink>
                            </li>
                        </div>

                    </List>
                </Box>

                <Box className='navsec-box'>
                    <div className='logo-img-div'>
                        <NavLink href="/">
                            <img className='logo-imgg' src={imgeLogo} alt="logo-img" />
                        </NavLink>
                        <h6> महाराष्ट्र राज्य दिव्यांग वित्त व विकास महामंडळ मर्या., मुंबई</h6>
                    </div>

                    <div className='imgbox'>
                        <img src="https://mshfdc.co.in/images/id-gov.png" alt="img" />
                    </div>
                    <div className="inputt-div d-flex">
                        <TextField className='inputt-area' variant="standard"
                            InputProps={{
                                disableUnderline: true,
                            }}
                            placeholder="What are your looking for?"
                        />
                        <Button className={`btnn ${props.colorMode === 'black' ? 'purpal' : props.colorMode === 'white' ? 'geen' : 'orange'}`} size='small' variant="contained"><SearchOutlinedIcon sx={{ mr: 0 }} /></Button>
                    </div>

                    <div className='logo-img-div pt-3 pb-2'>
                        <NavLink href="/">
                            <img className='logo-imgg' src={imglogo1} alt="logo-img" />
                        </NavLink>
                        <h5><b>महाराष्ट्र राज्य.</b> <p> दिव्यांग कल्याण विभाग</p></h5>
                    </div>

                </Box>

                <Box className='navthird-box'>
                    <List className={`${props.colorMode === 'black' ? 'purpal' : props.colorMode === 'white' ? 'geen' : 'orange'}`} sx={{ display: 'flex', textAlign: 'center', justifyContent: 'space-evenly', bgcolor: '#6d288c' }}>

                        <li className='nav-list'>
                            <NavLink>HOME</NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>ABOUT DISTRICT <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>DIRECTORY <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>DEPARTMENTS <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink >TOURISM <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink >DOCUMENTS <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>NOTICES <MdOutlineArrowDropDown style={{ marginBottom: '1px' }} /></NavLink>
                        </li>
                        <li className='nav-list'>
                            <NavLink>CITIZEN SERVICES </NavLink>
                        </li>

                    </List>
                </Box>
            </Box>
        </>
    )
}
export default LandingNavbar;