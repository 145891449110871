
import { useRef, useState } from "react";
import photo from "../../../../assets/WhatsApp Image 2023-11-06 at 12.55.53 PM.jpeg";
import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import log from '../../../../assets/logo/gov.png';
import log2 from '../../../../assets/logo/CM.9c7fd88cb8c8a7e2a12f.jpeg';

import html2canvas from 'html2canvas'
import jsPDF from "jspdf";
import SpecialSellerPopup from "../../../common/specialSellerSucessPopup/specialSellerSucessPopup/SpecialSellerSucessPopup";
function EnglishSellerSpecial() {
    const token = window.localStorage.getItem('token')
    const isLogin = window.localStorage.getItem('isLogin')
    const [modalShow, setModalShow] = useState(false);
    const navigate = useNavigate()
    const [mobileset, setMobileset] = useState()
    const [langId, setLangId] = useState()
    const [successData, setSuccessData] = useState()
    // console.log('successData', successData);
    // console.log('mobileset', mobileset);

    const [file, setFile] = useState();

    const [comboId, setComboId] = useState()
    const [addSeller, setAddSeller] = useState({
        bene_firstname: '',
        bene_lastname: '',
        bene_middlename: '',

        mother_firstname: '',
        mother_lastname: '',
        mother_middlename: '',

        dob: '',
        mobile: '',
        gender: '',

        bloodGroup: '',
        castCategory: '',

        maritalStatus: '',

        spouse_name: '',

        guardian_name: '',
        relatedToGuardian: '',

        businessCategory: '',
        language_id: '',
        email: '',
        disType: '',


        educationDetails: {
            primary: false,
            secondary: false,
            higher_secondary: false,
            diploma: false,
            graduate: false,
            post_graduate: false,
            unGraduate: false,
        },

        userPhoto: {
            public_id: null,
            url: null
        },

        signPhoto: {
            public_id: null,
            url: null
        },

        domacileCert: {
            public_id: null,
            url: null
        },

        coresAddress: '',

        village: '',
        block: '',
        district: '',
        state: '',
        pincode: '',

        permanentAddress: '',

        docOfAddProof: {
            driveLicen: false,
            rationCard: false,
            voterId: false,
            passport: false,
            electricBill: false,
            properTaxRec: false,
        },

        addressProof: [{
            public_id: null,
            url: null
        }],

        disabilityCert: false,
        udId_No: '',
        PerOfDis: '',

        typesOfDisability: {
            locoMotor: false,
            leprosy: false,
            cerebralPal: false,
            dwarfism: false,
            muscular_dy: false,
            acidAttactVic: false,
            blindness: false,
            lowVision: false,
            hearingImp: false,
            speechNlang: false,
            intellect: false,
            specific_learn: false,
            autism: false,
            mentalBehave: false,
            multiScler: false,
            parkinson: false,
            hemoPhilia: false,
            thalassemia: false,
            sickieCell: false,
            chronicNeu: false,
            multipleDisab: false,
        },

        disabltyByBirth: {
            type: false
        },

        disabtlySince: '',

        areaOfDisable: {
            chest: false,
            ears: false,
            nois: false,
            shoulder: false,
            throat: false,
            leftEye: false,
            leftHand: false,
            leftLeg: false,
            rightEye: false,
            rightHand: false,
            rightLeg: false,
            stomach: false,
            mouth: false,
        },

        employmentDetail: {
            status: '',
            bpl: '',
            annualIncom: ''
        },

        idProof: {
            type: ''
        },

        idProofFile: {
            public_id: null,
            url: null
        },

        idProofNo: '',
        adhaarCard: '',

        accHolderName: '',
        accNo: '',
        bankName: '',

        ifscCode: '',
        branchName: '',
        branchCode: '',

        frontOfBankPassBook: {
            public_id: null,
            url: null
        },

        affidavith: {
            public_id: null,
            url: null
        },

        businessYouLikeToDo: {
            foodItem: false,
            minor: false,
            independentBus: false,
            transport: false,
            other: false,
        },

        //declare
        declareName: '',
        declareGuard: '',
        declareAge: '',
        declareCaste: '',
        residentOf: '',
        declarePost: '',
        block: '',
        district: '',
        state: '',
        pinCode: '',


        // language_id: { type: mongoose.Schema.Types.ObjectId, ref: "languages" },

    })

    const [education, setEducation] = useState({
        primary: false,
        secondary: false,
        higher_secondary: false,
        diploma: false,
        graduate: false,
        post_graduate: false,
        unGraduate: false,
    })

    const [docAddressProfe, setDocAddressProfe] = useState({
        driveLicen: false,
        rationCard: false,
        voterId: false,
        passport: false,
        electricBill: false,
        properTaxRec: false,
    },
    )

    const [tOfDisability, setTOfDisability] = useState(
        {
            locoMotor: false,
            leprosy: false,
            cerebralPal: false,
            dwarfism: false,
            muscular_dy: false,
            acidAttactVic: false,
            blindness: false,
            lowVision: false,
            hearingImp: false,
            speechNlang: false,
            intellect: false,
            specific_learn: false,
            autism: false,
            mentalBehave: false,
            multiScler: false,
            parkinson: false,
            hemoPhilia: false,
            thalassemia: false,
            sickieCell: false,
            chronicNeu: false,
            multipleDisab: false,
        },
    )

    const [aOfDisabality, setAOfDisabality] = useState(
        {
            chest: false,
            ears: false,
            nois: false,
            shoulder: false,
            throat: false,
            leftEye: false,
            leftHand: false,
            leftLeg: false,
            rightEye: false,
            rightHand: false,
            rightLeg: false,
            stomach: false,
            mouth: false,
        },
    )

    const [empDetails, setEmpDetails] = useState({
        status: '',
        bpl: '',
        annualIncom: ''
    },
    )


    const [identityProof, setIdentityProof] = useState({
        type: ''
    })

    const [businessLike, setBussinessLike] = useState({
        foodItem: false,
        minor: false,
        independentBus: false,
        transport: false,
        other: false,
    })

    const [disabilitiesBirth, setDisabilitiesBirth] = useState(false)





    const changeHandle = (e, str) => {
        if (str) {
            const clone = { ...addSeller }
            const name = e.target.name
            clone[name] = str
            setAddSeller(clone)
        } else {
            const clone = { ...addSeller }
            const val = e.target.value
            const name = e.target.name
            clone[name] = val
            setAddSeller(clone)
        }

    }

    const handleChange = (e, bul) => {
        const clone = { ...education }
        const name = e.target.name
        clone[name] = bul
        setEducation(clone)
    }

    const AddressHandle = (e, address) => {
        const clone = { ...docAddressProfe }
        const name = e.target.name
        clone[name] = address
        setDocAddressProfe(clone)
    }
    const tOfDisabilityChange = (e, tOfDisabilitys) => {
        const clone = { ...tOfDisability }
        const name = e.target.name
        clone[name] = tOfDisabilitys
        setTOfDisability(clone)
    }
    const aOfDisabilityChange = (e, aOfDisabilitys) => {
        const clone = { ...aOfDisabality }
        const name = e.target.name
        clone[name] = aOfDisabilitys
        setAOfDisabality(clone)
    }

    const empDetailsChange = (e, str) => {
        if (str) {
            const clone = { ...empDetails }
            const name = e.target.name
            clone[name] = str
            setEmpDetails(clone)
        }
    }
    const IdentityProofChange = (e, str) => {
        if (str) {
            const clone = { ...identityProof }
            const name = e.target.name
            clone[name] = str
            setIdentityProof(clone)
        }
    }


    const busiYouLikeToDoChange = (e, busiYouLikeToDoChanges) => {
        const clone = { ...businessLike }
        const name = e.target.name
        clone[name] = busiYouLikeToDoChanges
        setBussinessLike(clone)
    }

    const disabtlyBirth = (e, disabtlyBirths) => {
        // const clone = { ...disabilitiesBirth }
        // const name = e.target.name
        // clone[name] = disabtlyBirths
        setDisabilitiesBirth(disabtlyBirths)
    }



    const [domacileImg, setDomacileImg] = useState('')
    const handleImage = async (e) => {
        // setDomacileImg(e.target.files[0])
        const imgs = new FormData()
        const obj = {}
        try {
            imgs.append('image', e.target.files[0])
            const res = await axios.post(`https://onlineparttimejobs.in/api/cloudinaryImage/addImage`, imgs)
            const obj = { public_id: res.data.public_id, url: res.data.url }
            console.log(obj.url);

            const clone = { ...addSeller }
            clone[e.target.name] = obj
            setAddSeller(clone)
            // console.log('cloneclone', clone);
            // console.log('cloneclone', e.target.name);
        } catch (error) {

        }
    }

    const [combo, setCombo] = useState(null)

    const [filterImage, setFilterImage] = useState(null)
    // console.log('combo', filterImage?.icon.url);
    const formCombo = async () => {
        try {
            const res = await axios.get(`https://onlineparttimejobs.in/api/category/child/${25}`, {
                withCredentials: true
            })
            setCombo(res?.data)

        } catch (error) {

        }
    }


    // console.log(comboId);

    const comboHandleChange = (e) => {
        setComboId(e.target.value)

        const filter = combo.find((item) => {
            return item?._id == e.target.value

        })
        setFilterImage(filter)


    }



    // console.log(addSeller);
    // console.log(education);
    // console.log(addSeller);
    // console.log(docAddressProfe);
    // console.log(tOfDisability);
    // console.log(aOfDisabality);
    // console.log(empDetails);
    // console.log(identityProof);
    // console.log(businessLike);

    const postData = async () => {
        // console.log(addSeller);
        const clone = { ...addSeller, educationDetails: education, docOfAddProof: docAddressProfe, typesOfDisability: tOfDisability, areaOfDisable: aOfDisabality, employmentDetail: empDetails, idProof: IdentityProofChange, businessYouLikeToDo: busiYouLikeToDoChange, disabltyByBirth: disabilitiesBirth, businessCategory: comboId, language_id: langId, mobile: mobileset }

        try {
            const res = await axios.post(`https://onlineparttimejobs.in/api/specialSeller/add_Seller`, clone, {
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            })

            setSuccessData(res.data)
            // console.log('post-data', res.data);
            if (res.data?.status == 500 || res?.status != 200) {
                alert('All Field are Mandatory')
            } else {
                setModalShow(true)
            }

        } catch (error) {

        }
        // console.log('clone', clone);
        // console.log(formData);

    }


    // const downloadPdf = () => {
    //     const capture = document.querySelector('actual-recipt')
    //     html2canvas(capture).then((canvas) => {
    //         const imgData = canvas.toDataURL('img/png')
    //         const doc = new jsPDF('p', 'mm', 'a4')
    //         const componentWidth = doc.internal.pageSize.getWidth()
    //         const componentHeight = doc.internal.pageSize.getHeight()
    //         doc.addImage(imgData, 'PNG', 0, 0, componentWidth, componentHeight)
    //         doc.save('receipt.pdf')
    //     })
    // }

    const pdfRef = useRef()
    const downloadPdf = () => {
        const input = pdfRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('img/png')
            const pdf = new jsPDF('p', 'mm', 'a4', true)
            const pdfWidth = pdf.internal.pageSize.getWidth()
            const pdfHeight = pdf.internal.pageSize.getHeight()
            const imgWidth = canvas.width
            const imgHeight = canvas.height
            const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
            const imgX = (pdfWidth - imgWidth * ratio) / 2
            const imgY = 30
            pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio)
            pdf.save('invoice.pdf')
        })
    }

    useEffect(() => {
        formCombo()
    }, [])

    useEffect(() => {
        setMobileset(window.localStorage.getItem('phoneNumber'))
    }, [])
    useEffect(() => {
        setLangId(window.localStorage.getItem('lang'))
    }, [])



    if (isLogin === 'true') {
        return (
            <>
                <div className="seller-special mb-3" ref={pdfRef}>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 conatct-seller">
                                <div className="border border-primary" >
                                    <div className="form-logo">
                                        <img src={log} alt="" />
                                    </div>
                                    <div className="col-md-12 text-center mt-2">
                                        <p className="fs-6 fw-bold">महाराष्ट्र राज्य दिव्यांग वित्त व विकास महामंडळ मर्या. मुंबई</p>
                                        <p className="fs-6 fw-bold">(ISO 9001 : 2008  Certified Organisation)</p>
                                        <span className="fs-6 fw-bold">
                                            Person with Disabilities Welfare Department, Government of Maharashtra
                                        </span>{" "}
                                        <br />
                                        <span>दिव्यांग कल्याण विभाग, महाराष्ट्र शासन </span>
                                    </div>
                                    <div className="form-logo-2">
                                        <img src={log2} alt="" />
                                    </div>
                                </div>
                                {/* <hr className='border border-1 border-secondary' /> */}
                                <div className="border border-primary">
                                    <div className="col-md-12 text-center mt-2">
                                        <span className="fs-6 ">
                                            Application form for Environmentally Friendly e-Vehicles/E-cart for Divyangjan to become self-reliant{" "}
                                        </span>{" "}
                                        <br />
                                        <span>दिव्यांगजनांना स्वावलंबी होण्यासाठी पर्यावरणपूरक ई-वाहने/ई-कार्टसाठी अर्ज</span>
                                    </div>
                                </div>
                                <div className="border border-primary actual-recipt" >
                                    <div className="col-md-12 mt-4">
                                        <h6>Personal Details/वैयक्तिक माहिती</h6>
                                        <hr className="border border-1 border-primary m-0 p-0" />
                                    </div>
                                    {successData?.applicationNo ? <h3 className="text-center">Your Registration No: <span style={{ color: 'red' }}>{successData?.applicationNo}</span></h3> : ''}
                                    {successData?.applicationDate ? <h3 className="text-center">Your Registration Date :<span style={{ color: 'red' }}>{successData?.applicationDate}</span></h3> : ''}

                                    <div className="row m-0 p-0">
                                        <div className="col-md-9 mt-3 m-0 p-0">
                                            <ul type="disc" className="disc-2" style={{ listStyle: "unset" }}>
                                                <li className="">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Name of Beneficiary/लयभयर्थ्चे नयि <sup>*</sup>
                                                    </label>
                                                    <br/>

                                                    <input 
                                                    type="text"
                                                     placeholder="First Name" 
                                                     className="pl-2 mr-2" 
                                                     name="bene_firstname" 
                                                     value={addSeller.bene_firstname} 
                                                     onChange={changeHandle} />
                                                    <input
                                                        type="text"
                                                        className="pl-2 mr-2"
                                                        placeholder="Middle name"
                                                        name="bene_middlename"
                                                        value={addSeller.bene_middlename}
                                                        onChange={changeHandle}
                                                    />
                                                    
                                                    <input
                                                        type="text"
                                                        placeholder="Last Name"
                                                        className="pl-2"
                                                        name="bene_lastname"
                                                        value={addSeller.bene_lastname}
                                                        onChange={changeHandle}
                                                    />{" "}


                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याचेनाव <sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="आडनाव" className="pl-2" />{" "}
                                    <input type="text" placeholder="नाव" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        className="pl-2"
                                        placeholder="वडिलांचे/तिचे नाव"
                                    />
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Mothers Name/आईचे नयि <sup>*</sup>
                                                    </label>
                                                    <div>
                                                        <input type="text" placeholder="First Name" className="pl-2" name="mother_firstname" value={addSeller.mother_firstname} onChange={changeHandle} />{" "}
                                                        <input
                                                            type="text"
                                                            className="pl-2"
                                                            placeholder="Middle name"
                                                            name="mother_middlename"
                                                            value={addSeller.mother_middlename}
                                                            onChange={changeHandle}
                                                        />
                                                        <input
                                                            type="text"
                                                            placeholder="Last Name"
                                                            className="pl-2"
                                                            name="mother_lastname"
                                                            value={addSeller.mother_lastname}
                                                            onChange={changeHandle}
                                                        />{" "}

                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आईचे नाव<sup>*</sup>
                                </label>
                                <div>
                                    <input type="text" placeholder="आडनाव" className="pl-2" />{" "}
                                    <input type="text" placeholder="नाव" className="pl-2" />{" "}
                                    <input type="text" className="pl-2" placeholder="तिचे नाव" />
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Date of Birth/र्जन्मतयरीख<sup>*</sup>
                                                    </label>
                                                    <div>
                                                        <input type="date" className="pl-2" name="dob" value={addSeller.dob} onChange={changeHandle} />{" "}
                                                        <input
                                                            type="text"
                                                            placeholder="Age(Min 18 to Max 55)"
                                                            className="ml-5 pl-2"
                                                            style={{ width: 250 }}
                                                            name="dob"
                                                            value={addSeller.dob}
                                                            onChange={changeHandle}
                                                        />
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    जन्मतारीख<sup>*</sup>
                                </label>
                                <div>
                                    <input type="date" className="pl-2" />{" "}
                                    <input
                                        type="text"
                                        placeholder="लाभार्थ्याचे वय (किमान १८ वर्षे ते कमाल ५५ वर्षे)"
                                        className="ml-5 pl-2"
                                        style={{ width: 250 }}
                                    />
                                </div>
                            </li> */}

                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Mobile No./भ्रमणध्नी क<sup>*</sup>
                                                    </label>
                                                    <div>
                                                        <input type="number " placeholder="+91" className="pl-2" name="mobileset" value={mobileset} onChange={changeHandle} />{" "}
                                                        {/* <input
                                            type="text"
                                            placeholder="भ्रमणध्वनी क्र. +९१"
                                            className="ml-5 pl-2"
                                            style={{ width: 250 }}
                                            onChange={changeHandle}
                                        /> */}
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Email./ईमेल<sup>*</sup>
                                                    </label>
                                                    <div>
                                                        <input type="email" placeholder="example@gmail.com" className="pl-2" name="email" value={addSeller.email} onChange={changeHandle} />{" "}
                                                        {/* <input
                                            type="text"
                                            placeholder="भ्रमणध्वनी क्र. +९१"
                                            className="ml-5 pl-2"
                                            style={{ width: 250 }}
                                            onChange={changeHandle}
                                        /> */}
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Gender/लिंग<sup>*</sup>
                                                    </label>
                                                    <div className="Gender">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="gender" value={addSeller.gender} id="flexRadioDefault1" onChange={(e) => { changeHandle(e, 'Male') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault1">
                                                                Male/पु
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="gender" value={addSeller.gender} id="flexRadioDefault2" onChange={(e) => { changeHandle(e, 'Female') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault2">
                                                                Female/स्त्री
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="gender" value={addSeller.gender} id="flexRadioDefault3" onChange={(e) => { changeHandle(e, 'Trans') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault3">
                                                                Transgender/ट्रान्सजेडर
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="gender" value={addSeller.gender} id="flexRadioDefault4" onChange={(e) => { changeHandle(e, 'Other') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault4">
                                                                Other/इतर
                                                            </label>
                                                        </div>

                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-3 mt-4 text-center">
                                            <img src={addSeller?.userPhoto?.url ? addSeller?.userPhoto?.url : photo} style={{width:'200px'}} alt="Photograph" className="border border-primary" />
                                            <input type="file" className='mt-1 ' name="userPhoto" id="img" onChange={handleImage} />
                                            <label for="img" >Photograph</label>
                                            <img src={addSeller?.signPhoto?.url ? addSeller?.signPhoto?.url : photo} style={{width:'200px'}} alt="nizam" className="border border-primary" />
                                            <input type="file" className='mt-1 ' name="signPhoto" id="img" onChange={handleImage} />
                                            <label for="img" >Upload Signature</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <ul type="disc " style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Blood Group/रक्त गट
                                                    </label>
                                                    <div className="Blood-Group">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault5" onChange={(e) => { changeHandle(e, 'O+') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault5">
                                                                O+
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault6" onChange={(e) => { changeHandle(e, 'O-') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault6">
                                                                O-
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault7" onChange={(e) => { changeHandle(e, 'A') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault7">
                                                                A
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault8" onChange={(e) => { changeHandle(e, 'A+') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault8">
                                                                A+
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault9" onChange={(e) => { changeHandle(e, 'A-') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault9">
                                                                A-
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault10" onChange={(e) => { changeHandle(e, 'B') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault10">
                                                                B
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault11" onChange={(e) => { changeHandle(e, 'B-') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault11">
                                                                B-
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault12" onChange={(e) => { changeHandle(e, 'AB+') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault12">
                                                                AB+
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="bloodGroup" value={addSeller.bloodGroup} id="flexRadioDefault13" onChange={(e) => { changeHandle(e, 'AB-') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault13">
                                                                AB-
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Category/र्जयत/प्रिग <sup>*</sup>
                                                    </label>
                                                    <div className="Category-1">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault14" onChange={(e) => { changeHandle(e, 'General') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault14">
                                                                General
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault16" onChange={(e) => { changeHandle(e, 'OBC') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault16">
                                                                OBC
                                                            </label>
                                                        </div>

                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault17" onChange={(e) => { changeHandle(e, 'SC') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault17">
                                                                SC
                                                            </label>
                                                        </div>

                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault18" onChange={(e) => { changeHandle(e, 'ST') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault18">
                                                                ST
                                                            </label>
                                                        </div>

                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault19" onChange={(e) => { changeHandle(e, 'VJNT') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault19">
                                                                VINT
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault20" onChange={(e) => { changeHandle(e, 'NT-B') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault20">
                                                                NT-B
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault21" onChange={(e) => { changeHandle(e, 'NT-C') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault21">
                                                                NT-C
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="castCategory" value={addSeller.castCategory} id="flexRadioDefault22" onChange={(e) => { changeHandle(e, 'NT-D') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault22">
                                                                NT-D
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <span>
                                                        (Atteched Cast Certificate and Non-Creamy Layer Certificate
                                                        for OBC/SC/ST/VJNT/NT-B/NT-C/NT-D){" "}
                                                    </span>
                                                </li>

                                                <li className="mt-3" >
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Business Category/व्यवसाय श्रेणी <sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <select class="form-select" style={{ width: '300px' }} aria-label="Default select example" onChange={comboHandleChange}>
                                                            <option>Business Category</option>
                                                            {combo && combo?.map((item) => {
                                                                return <option value={item?._id}>{item?.name}</option>
                                                            })}
                                                        </select>

                                                    </div>

                                                </li>
                                                <div>
                                                    <img src={filterImage?.icon?.url} style={{ width: '300px' }} alt="" />
                                                </div>


                                                <li className="mt-3" >
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Disability Category/अपंगत्व श्रेणी <sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <select class="form-select" style={{ width: '300px' }} name="disType" aria-label="Default select example" onChange={changeHandle}>
                                                            <option value={''}>Disability Category</option>
                                                            <option value={'Mild'}>Mild</option>
                                                            <option value={'Mild'}>Severe</option>

                                                        </select>

                                                    </div>

                                                </li>

                                               

                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    जात/प्रवर्ग<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        सर्वसाधारण
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        इतर मागास वर्ग
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अनुसुचित जाती
                                    </label>
                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अनुसुचित जमाती
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विमुक्त जाती
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती-ब
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती क
                                    </label>

                                    <input type="checkbox" className="ml-5 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        भटक्या जमाती-ड
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="file" className=" " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        (जात प्रमाणपत्र अपलोड करणे)
                                    </label>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Marital Status/वैवाहिक स्थिती <sup>*</sup>
                                                    </label>
                                                    <div className="Marital-Status">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="maritalStatus" value={addSeller.maritalStatus} id="flexRadioDefault23" onChange={(e) => { changeHandle(e, 'Unmarried') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault23">
                                                                Unmarried
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="maritalStatus" value={addSeller.maritalStatus} id="flexRadioDefault24" onChange={(e) => { changeHandle(e, 'Married') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault24">
                                                                Married
                                                            </label>
                                                        </div>

                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="maritalStatus" value={addSeller.maritalStatus} id="flexRadioDefault25" onChange={(e) => { changeHandle(e, 'Widow') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault25">
                                                                Widow
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" name="maritalStatus" value={addSeller.maritalStatus} id="flexRadioDefault26" onChange={(e) => { changeHandle(e, 'Divorced') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault26">
                                                                Divorced
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    वैवाहिक स्थिती <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        अविवाहित
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विवाहित{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विधव
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        घटस्फोटीत
                                    </label>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        If you are married, then please provide name of the Spouse/तुम्ही विवाहित असाल तर कृपया तुमच्या जोडीदाराचे नाव द्या
                                                        <sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="text" className="pl-2" name="spouse_name" value={addSeller.spouse_name} onChange={changeHandle} />
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    विवाहित असल्यास पतिचे/पत्निचे संपूर्ण नाव<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Name of Guardian of the beneficiary/लाभार्थीच्या पालकाचे नाव<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="text" className="pl-2" name="guardian_name" value={addSeller.guardian_name} onChange={changeHandle} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        His/her Contact No<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="number" className="pl-2" placeholder="+91" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याच्या पालकाचे/सहाय्यकाचे नाव<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li> */}
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    पालकाचा/सहायकाचा भ्रमणध्वनी क्र.<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="number" className="pl-2" placeholder="+91" />
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Relationship of Guardian with the beneficiary/पालकाचा लाभार्थीशी संबंध<sup>*</sup>
                                                    </label>
                                                    <div className="Relationship">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault27" name="relatedToGuardian" value={addSeller.relatedToGuardian} onChange={(e) => { changeHandle(e, 'Father') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault27">
                                                                Father
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault28" name="relatedToGuardian" value={addSeller.relatedToGuardian} onChange={(e) => { changeHandle(e, 'Mother') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault28">
                                                                Mother{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault29" name="relatedToGuardian" value={addSeller.relatedToGuardian} onChange={(e) => { changeHandle(e, 'Wife') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault29">
                                                                Wife
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault30" name="relatedToGuardian" value={addSeller.relatedToGuardian} onChange={(e) => { changeHandle(e, 'Husband') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault30">
                                                                Husband
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault31" name="relatedToGuardian" value={addSeller.relatedToGuardian} onChange={(e) => { changeHandle(e, 'Other') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault31">
                                                                Other
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्यासोबतचे नाते<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        वडिल
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आई{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पति
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पत्नि
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        इतर
                                    </label>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Educational Details/शैक्षदणक अहगतय<sup>*</sup>
                                                    </label>
                                                    <div className="Educational">
                                                        <input type="checkbox" className="seller-checkbox " name="primary" value={education.primary} onChange={(e) => { handleChange(e, !education.primary) }} />
                                                        <label htmlFor="" className="ml-1  ">
                                                            Primary
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox  " name="secondary" value={education.secondary} onChange={(e) => { handleChange(e, !education.secondary) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Secondary{" "}
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="higher_secondary" value={education.higher_secondary} onChange={(e) => { handleChange(e, !education.higher_secondary) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Higher Secondary
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="diploma" value={education.diploma} onChange={(e) => { handleChange(e, !education.diploma) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Diploma
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="graduate" value={education.graduate} onChange={(e) => { handleChange(e, !education.graduate) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Graduate
                                                        </label>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <input type="checkbox" className=" seller-checkbox " name="post_graduate" value={education.post_graduate} onChange={(e) => { handleChange(e, !education.post_graduate) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Postgraduate
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="unGraduate" value={education.unGraduate} onChange={(e) => { handleChange(e, !education.unGraduate) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Uneducated
                                                        </label>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    शैक्षणिक अर्हता<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        प्राथमिक
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        माध्यमिक{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        उच्च माध्यमिक
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        डिप्लोमा
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पदवीधर
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पदव्युत्तर
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अशिक्षित
                                    </label>
                                </div>
                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <h6>Address Details/अर्जदाराचे निवासाचे तपशिल</h6>
                                        <hr className="border border-1 border-primary m-0 p-0" />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Are you being resident of Maharashtra State?
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault32" name="residentOf" value={addSeller.residentOf} onChange={(e) => { changeHandle(e, 'Yes') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDef   ault32">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault33" name="residentOf" value={addSeller.residentOf} onChange={(e) => { changeHandle(e, 'No') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault33">
                                                                No
                                                            </label>
                                                        </div>
                                                        <span className="ml-3">
                                                            (If Yes Please Upload Domicile Certificate)
                                                        </span>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आपण महाराष्ट्र राज्याचे रहिवासी आहत का?
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही
                                    </label>
                                    <span className="ml-3">
                                        (असल्यास अधिवास प्रमाणपत्र अपलोड करावे)
                                    </span>
                                </div>
                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Domacile Certificate/अधिवास प्रमाणपत्र<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="file" className="pl-2" name="domacileCert" onChange={handleImage} />
                                                        <span>
                                                            (कागदपत्रे जेपीईजी/पीडीफ स्वरूपातच असावा. कागदपत्राचा आकार
                                                            किमान ७५ केबी आणि कमाल २५६ केबी इतकी असावी)
                                                        </span>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Correspondence Address / सध्याचा पत्ता<sup>*</sup>
                                                    </label>
                                                    <div className="Correspondence">
                                                        <textarea
                                                            name="coresAddress"
                                                            id=""
                                                            cols="80"
                                                            rows="2"
                                                            className="pl-2" value={addSeller.coresAddress} onChange={changeHandle}></textarea>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div className="Village-sec ml-5">
                                                <div className="col-md-4">
                                                    <li className="">
                                                        {" "}
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Village/गांव<sup>*</sup>
                                                        </label>
                                                        <div className="Village">
                                                            <input type="text" className="pl-2" name="village" value={addSeller.village} onChange={changeHandle} />
                                                        </div>
                                                    </li>
                                                </div>
                                                <div className="col-md-4">
                                                    <li className="">
                                                        {" "}
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Block/तालुका.<sup>*</sup>
                                                        </label>
                                                        <div className="d-flex">
                                                            <input type="text" className="pl-2" name="block" value={addSeller.block} onChange={changeHandle} />
                                                        </div>
                                                    </li>
                                                </div>
                                                <div className="col-md-4">
                                                    <li className="">
                                                        {" "}
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            District/जिल्हा<sup>*</sup>
                                                        </label>
                                                        <div className="d-flex">
                                                            <input type="text" className="pl-2" name="district" value={addSeller.district} onChange={changeHandle} />
                                                        </div>
                                                    </li>
                                                </div>
                                            </div>
                                            <div className="d-flex ml-5">
                                                <div className="col-md-4">
                                                    <li className="mt-3">
                                                        {" "}
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            State / राज्य<sup>*</sup>
                                                        </label>
                                                        <div className="d-flex">
                                                            <input type="text" className="pl-2" name="state" value={addSeller.state} onChange={changeHandle} />
                                                        </div>
                                                    </li>
                                                </div>
                                                <div className="col-md-4">
                                                    <li className="mt-3">
                                                        {" "}
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Pincode / पिनकोड<sup>*</sup>
                                                        </label>
                                                        <div className="d-flex">
                                                            <input type="text" className="pl-2" name="pincode" value={addSeller.pincode} onChange={changeHandle} />
                                                        </div>
                                                    </li>
                                                </div>
                                            </div>
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Permanent Address / कायमचा पत्ता<sup>*</sup>
                                                    </label>
                                                    <div className="Permanent">
                                                        <textarea
                                                            name="permanentAddress"
                                                            id=""
                                                            cols="80"
                                                            rows="2"
                                                            className="pl-2" value={addSeller.permanentAddress} onChange={changeHandle}></textarea>
                                                    </div>
                                                </li>
                                            </ul>
                                            {/* <div className="d-flex ml-5">
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Village/गांव<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Block/तालुका.<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        District/जिल्हा<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                        </div> */}
                                            {/* <div className="d-flex ml-5">
                            <div className="col-md-4">
                                <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        State / राज्य<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                            <div className="col-md-4">
                                <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Pincode / पिनकोड<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </div>
                        </div> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Documents of Address Proof/पत्त्याच्या पुराव्याची कागदपत्रे <sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="checkbox" className="seller-checkbox " name="driveLicen" value={docAddressProfe.driveLicen} onChange={(e) => { AddressHandle(e, !docAddressProfe.driveLicen) }} />
                                                        <label htmlFor="" className="ml-1 seller-label" >
                                                            Driving License
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox  " name="rationCard" value={docAddressProfe.rationCard} onChange={(e) => { AddressHandle(e, !docAddressProfe.rationCard) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Ration Card
                                                        </label>
                                                        <input type="checkbox" className=" ml-3 seller-checkbox " name="voterId" value={docAddressProfe.voterId} onChange={(e) => { AddressHandle(e, !docAddressProfe.voterId) }} />
                                                        <label htmlFor="" className="ml-1  ">
                                                            Voter Id
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox  " name="passport" value={docAddressProfe.passport} onChange={(e) => { AddressHandle(e, !docAddressProfe.passport) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Passport{" "}
                                                        </label>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <input type="checkbox" className=" seller-checkbox " name="electricBill" value={docAddressProfe.electricBill} onChange={(e) => { AddressHandle(e, !docAddressProfe.electricBill) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Electricity Bill
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox  " name="docOfAddProof" value={docAddressProfe.properTaxRec} onChange={(e) => { AddressHandle(e, !docAddressProfe.properTaxRec) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Property Tax Receipt
                                                        </label>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    निवासी असल्यासचा पुरावा <sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        वाहन परवाना
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शिधापत्रिका
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        मतदान कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पारपत्र{" "}
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        विज बिल
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मालमता कर पावती
                                    </label>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Address proof / पत्त्याचा पुरावा<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="file" className="pl-2" name="addressProof" onChange={handleImage} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <h6>Disability Certificate/ दिव्यांगत्व प्रमाणपत्र</h6>
                                        <hr className="border border-1 border-primary m-0 p-0" />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Do You have Disability Certificate /तुमच्याकडे अपंगत्व प्रमाणपत्र आहे का<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault34" name="disabilityCert" value={addSeller.disabilityCert} onChange={(e) => { changeHandle(e, true) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault33">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault34" name="disabilityCert" value={addSeller.disabilityCert} onChange={(e) => { changeHandle(e, false) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault33">
                                                                No
                                                            </label>
                                                        </div>
                                                        <span className="ml-3">
                                                            {" "}
                                                            (If Yes, Please fill in the following Details & attach
                                                            disability certificate)
                                                        </span>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    आपल्याकडे दिव्यांगत्व प्रमाणपत्र आहे का?
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1  ">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही
                                    </label>
                                    <span className="ml-3">
                                        (असल्यास अधिवास प्रमाणपत्र अपलोड करावे)
                                    </span>
                                </div>
                                <div className="">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        Disability Certificate/ दिव्यांगत्व प्रमाणपत्र
                                    </label>
                                    <input type="file" name="" id="" />
                                </div>
                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        UDID Certificate No/UDID प्रमयणपत्र क<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="text" className="pl-2" name="udId_No" value={addSeller.udId_No} onChange={changeHandle} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Date of issue<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="date" className="pl-2" />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6">
                                            {/* <ul style={{ listStyle: "unset" }}>
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        UDID प्रमाणपत्र क्र<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li>
                            </ul> */}
                                        </div>
                                        <div className="col-md-6">
                                            {/* <ul style={{ listStyle: "unset" }}>
                                <li className="">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        प्रमाणपत्र दिल्याचा दिनांक<sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="date" className="pl-2" />
                                    </div>
                                </li>
                            </ul> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Percentage of disability (40% or more than 40%)/अपंगत्वाची टक्केवारी (40% किंवा 40% पेक्षा जास्त)<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="text" className="pl-2" name="PerOfDis" value={addSeller.PerOfDis} onChange={changeHandle} />
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                    {" "}
                                    <label htmlFor="" className="fw-bold mr-2">
                                        दिव्यंगत्वाचे प्रमाण (किमान ४०% अथवा त्यापेक्षा अधिक)
                                        <sup>*</sup>
                                    </label>
                                    <div className="d-flex">
                                        <input type="text" className="pl-2" />
                                    </div>
                                </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Types Of Disability/अपंगत्वाचे प्रकार<sup>*</sup>
                                                    </label>
                                                    <div className="Disability">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="locoMotor" value={tOfDisability.locoMotor} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.locoMotor) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault35">
                                                                Locomotors Disability
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="leprosy" value={tOfDisability.leprosy} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.leprosy) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault36">
                                                                Leprosy Cured persons
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="cerebralPal" value={tOfDisability.cerebralPal} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.cerebralPal) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault37">
                                                                Cerebral Palsy
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="dwarfism" value={tOfDisability.dwarfism} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.dwarfism) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault38">
                                                                Dwarfism{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="muscular_dy" value={tOfDisability.muscular_dy} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.muscular_dy) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault39">
                                                                Muscular Dystrophy
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="acidAttactVic" value={tOfDisability.acidAttactVic} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.acidAttactVic) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault40">
                                                                Acid Attack victim
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="blindness" value={tOfDisability.blindness} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.blindness) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault41">
                                                                Blindness
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="lowVision" value={tOfDisability.lowVision} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.lowVision) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault42">
                                                                Low-vision
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="hearingImp" value={tOfDisability.hearingImp} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.hearingImp) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault43">
                                                                Hearing Impairment (Deaf and hard of Hearing)
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="speechNlang" value={tOfDisability.speechNlang} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.speechNlang) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault44">
                                                                Speech and Language disability
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="intellect" value={tOfDisability.intellect} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.intellect) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault45">
                                                                Intellectual Disability
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="specific_learn" value={tOfDisability.specific_learn} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.specific_learn) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault46">
                                                                Specific Learning Disabilities
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="autism" value={tOfDisability.autism} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.autism) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault47">
                                                                Autism Spectrum Disorder
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="mentalBehave" value={tOfDisability.mentalBehave} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.mentalBehave) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault48">
                                                                Mental Behavior/Mental illness
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="multiScler" value={tOfDisability.multiScler} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.multiScler) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault49">
                                                                Multiple Sclerosis
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="parkinson" value={tOfDisability.parkinson} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.parkinson) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault50">
                                                                Parkinson's disease
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="hemoPhilia" value={tOfDisability.hemoPhilia} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.hemoPhilia) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault52">
                                                                Hemophilia
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="thalassemia" value={tOfDisability.thalassemia} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.thalassemia) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault53">
                                                                Thalassemia
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="sickieCell" value={tOfDisability.sickieCell} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.sickieCell) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault54">
                                                                Sickle Cell disease
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="chronicNeu" value={tOfDisability.chronicNeu} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.chronicNeu) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault55">
                                                                Chronic Neurological conditions
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" name="multipleDisab" value={tOfDisability.multipleDisab} onChange={(e) => { tOfDisabilityChange(e, !tOfDisability.multipleDisab) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault56">
                                                                Multiple Disabilities
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    दिव्यांगत्वाचा प्रकार<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अस्थिव्यंग
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        कुष्ठरोग निवारीत मुक्त अंशतः अंध (दृष्टिदोष)
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        मेंदूचा पक्षाघात
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शारीरिक वाढ खुंटणे{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        स्नायुंची विकृती
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आम्ल हल्ला पीडित
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        पुर्णतः अंध
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        अंशतः अंध
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        कर्णबधीर / ऐकू कमी येणे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        वाचा / भाषा दोष
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        बौद्धिक अक्षम
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        विशिष्ट अध्ययन अक्षम
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        स्वमग्न
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        मानसिक वर्तन/मानसिक आधार
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        हातापायातील स्नायू कमजोर / शिथिल होणे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        कंपावात
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        अधिक रक्तस्त्राव
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        {" "}
                                        रक्ताची कमतरता
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        रक्ताचे हिमोग्लोबीनचे प्रमाण कमी होणे
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मज्जासंस्थेचे तीव्र आजार
                                    </label>

                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        बहुविकलांग
                                    </label>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Do you have Disability by Birth/अपंगत्वाचे क्षेत्र<sup>*</sup>
                                                    </label>
                                                    <div className="have-Disability">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault57" name="disabilitiesBirth" value={disabilitiesBirth} onChange={(e) => { disabtlyBirth(e, !disabilitiesBirth) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault57">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault58" name="disabilitiesBirth" value={disabilitiesBirth} onChange={(e) => { disabtlyBirth(e, !disabilitiesBirth) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault58">
                                                                No
                                                            </label>
                                                        </div>
                                                        <label htmlFor="" className="ml-5 seller-label">
                                                            If yes then Disability Since
                                                        </label>
                                                        <input type="date" className="ml-3" name="disabtlySince" value={addSeller.disabtlySince} onChange={changeHandle} />
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    तुम्हाला जन्मतःच दिव्यांगत्व आहे का होय<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        होय{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नाही{" "}
                                    </label>
                                    <label htmlFor="" className="ml-5 seller-label">
                                        जर होय तर दिव्यांगत्व कधीपासून आहे
                                    </label>
                                    <input type="date" className="ml-3" />
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Area of Disability/अपंगत्वाचे क्षेत्र<sup>*</sup>
                                                    </label>
                                                    <div className="Area-of-Disability">
                                                        <input type="checkbox" className="seller-checkbox " name="chest" value={aOfDisabality.chest} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.chest) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Chest
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox  " name="ears" value={aOfDisabality.ears} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.ears) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            {" "}
                                                            Ears
                                                        </label>
                                                        <input type="checkbox" className=" ml-3 seller-checkbox " name="leftEye" value={aOfDisabality.leftEye} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.leftEye) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Left Eye
                                                        </label>
                                                        <input type="checkbox" className=" ml-3 seller-checkbox " name="leftHand" value={aOfDisabality.leftHand} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.leftHand) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Left Hand
                                                        </label>
                                                        <input type="checkbox" className=" ml-3 seller-checkbox " name="leftLeg" value={aOfDisabality.leftLeg} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.leftLeg) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Left Leg
                                                        </label>
                                                        <input type="checkbox" className=" ml-3 seller-checkbox " name="mouth" value={aOfDisabality.mouth} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.mouth) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Mouth
                                                        </label>
                                                    </div>
                                                    <div className="Area-of-Disability mt-3">
                                                        <input type="checkbox" className=" seller-checkbox  " name="nois" value={aOfDisabality.nois} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.nois) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Nois
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="shoulder" value={aOfDisabality.shoulder} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.shoulder) }} />
                                                        <label htmlFor="" className="ml-1 seller-label ">
                                                            Shoulder
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox  " name="throat" value={aOfDisabality.throat} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.throat) }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Throat
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="rightEye" value={aOfDisabality.rightEye} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.rightEye) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Right Eye
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="rightLeg" value={aOfDisabality.rightLeg} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.rightLeg) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Right Leg
                                                        </label>
                                                        <input type="checkbox" className="ml-3 seller-checkbox " name="stomach" value={aOfDisabality.stomach} onChange={(e) => { aOfDisabilityChange(e, !aOfDisabality.stomach) }} />
                                                        <label htmlFor="" className="ml-1  seller-label">
                                                            Stomach
                                                        </label>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    {" "}
                                    दिव्यंगत्वाचे क्षेत्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        छाती
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        कान
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        डावा डोळा
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        डावा हात
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        डावा हात
                                    </label>
                                    <input type="checkbox" className=" ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        Mouth
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className=" seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        आवाज
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label ">
                                        {" "}
                                        खांदा
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        {" "}
                                        घसा
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        उजवा डोळा{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        {" "}
                                        उजवा पाय
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox " />
                                    <label htmlFor="" className="ml-1  seller-label">
                                        {" "}
                                        पोट
                                    </label>
                                </div>
                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <h6>Employment Details /रोजगाराची सद्दस्थिती</h6>
                                        <hr className="border border-1 border-primary m-0 p-0" />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Employment Status/रोजगार स्थिती<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault60" name="status" value={empDetails.status} onChange={(e) => { empDetailsChange(e, 'Employed') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault60">
                                                                Employed
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault61" name="status" value={empDetails.status} onChange={(e) => { empDetailsChange(e, 'Unemployed') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault62">
                                                                Unemployed
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    रोजगाराची स्थिती<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        नोकरीला आहे
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        बेरोजगार{" "}
                                    </label>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        BPL/APL/बीपीएल/एपीएल<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault62" name="bpl" value={empDetails.bpl} onChange={(e) => { empDetailsChange(e, 'N/A') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault62">
                                                                N/A
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault63" name="bpl" value={empDetails.bpl} onChange={(e) => { empDetailsChange(e, 'APL') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault63">
                                                                APL{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault64" name="bpl" value={empDetails.bpl} onChange={(e) => { empDetailsChange(e, 'BPL') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault64">
                                                                BPL{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault65" name="bpl" value={empDetails.bpl} onChange={(e) => { empDetailsChange(e, 'Antoday') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault65">
                                                                Antoday{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    दारिद्र्यरेषेच्या वर / दारिद्र्यरेषेच्या खालील<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        N/A
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        APL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        BPL{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        Antoday{" "}
                                    </label>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Annual Income of the beneficiary/लाभार्थीचे वार्षिक उत्पन्न<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault66" name="annualIncom" value={empDetails.annualIncom} onChange={(e) => { empDetailsChange(e, 'Below 10k') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault66">
                                                                Below 10k{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault67" name="annualIncom" value={empDetails.annualIncom} onChange={(e) => { empDetailsChange(e, '10k to 1 lakhs') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault67">
                                                                10k to 1 lakhs{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault68" name="annualIncom" value={empDetails.annualIncom} onChange={(e) => { empDetailsChange(e, '01 lakhs to 2.5 lakhs') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault68">
                                                                01 lakhs to 2.5 lakhs{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="radio" id="flexRadioDefault69" name="annualIncom" value={empDetails.annualIncom} onChange={(e) => { empDetailsChange(e, '25 lakhs') }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault69">
                                                                25 lakhs{" "}
                                                            </label>
                                                        </div>

                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभार्थ्याचे वार्षिक उत्पन्न<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        १० हजार पैक्षा कमी
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        १० हजार ते १ लक्ष{" "}
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        लक्ष ते २.५ लक्ष
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        २.५ लक्ष{" "}
                                    </label>
                                </div>
                            </li> */}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <h6>Identity Details (Min-1)/ओळखीचा पुरावा (किमान - १ )</h6>
                                        <hr className="border border-1 border-primary m-0 p-0" />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul style={{ listStyle: "unset" }}>
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Identity Proof/ओळखपत्र क<sup>*</sup>
                                                    </label>
                                                    <div className="Identity-proof">
                                                        <input type="radio" className="seller-checkbox " name="type" value={identityProof.type} onChange={(e) => { IdentityProofChange(e, 'Driving License') }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Driving License
                                                        </label>
                                                        <input type="radio" className="ml-3 seller-checkbox  " name="type" value={identityProof.type} onChange={(e) => { IdentityProofChange(e, 'PAN Card') }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            PAN Card
                                                        </label>
                                                        <input type="radio" className="ml-3 seller-checkbox  " name="type" value={identityProof.type} onChange={(e) => { IdentityProofChange(e, 'Ration Card') }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Ration Card
                                                        </label>
                                                        <input type="radio" className="ml-3 seller-checkbox  " name="type" value={identityProof.type} onChange={(e) => { IdentityProofChange(e, 'Voter ID') }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Voter ID
                                                        </label>
                                                        <input type="radio" className="ml-3 seller-checkbox  " name="type" value={identityProof.type} onChange={(e) => { IdentityProofChange(e, 'Aadhar Card') }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Aadhar Card
                                                        </label>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <input type="radio" className="seller-checkbox " name="type" value={identityProof.type} onChange={(e) => { IdentityProofChange(e, 'Passport') }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            Passport
                                                        </label>
                                                        <input type="radio" className="ml-3 seller-checkbox  " name="type" value={identityProof.type} onChange={(e) => { IdentityProofChange(e, 'MGNERGA Job Card') }} />
                                                        <label htmlFor="" className="ml-1 seller-label">
                                                            MGNERGA Job Card
                                                        </label>
                                                    </div>
                                                    <div className="d-flex mt-3">
                                                        <input type="file" />
                                                        <span className="fw-bold">
                                                            (कागदपत्रे जेपीईजी/पीडी स्वरूपातच असावा कागदपत्राचा आकार
                                                            किमान ४५ केबी आणि कमाल २५६ केबी इतकी असावी)
                                                        </span>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                {" "}
                                <label htmlFor="" className="fw-bold mr-2">
                                    ओळखपत्र प्रमाणपत्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        वाहन परवाना
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पॅन कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        शिधापत्रिका
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मतदान कार्ड
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        आधार कार्ड
                                    </label>
                                </div>
                                <div className="d-flex mt-3">
                                    <input type="checkbox" className="seller-checkbox " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        पारपत्र
                                    </label>
                                    <input type="checkbox" className="ml-3 seller-checkbox  " />
                                    <label htmlFor="" className="ml-1 seller-label">
                                        मग्रारोहयो जॉब कार्ड
                                    </label>
                                </div>
                                
                            </li> */}
                                                <li className="mt-3">
                                                    {" "}
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Identity Proof No./ओळख पुरावा क्र.<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="file" className="pl-2" name="idProofFile" onChange={handleImage} />
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    ओळखपत्र क्र<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="text" className="pl-2" />
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    <div className="d-flex">
                                                        <div className="col-md-6">
                                                            <label htmlFor="" className="fw-bold">
                                                                Aadhar Card No./आधयर क्रमयांक<sup>*</sup>
                                                            </label>
                                                            <div className="d-flex">
                                                                <input type="text" className="pl-2" name="adhaarCard" value={addSeller.adhaarCard} onChange={changeHandle} />
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-6">
                                        <label htmlFor="" className="fw-bold ">
                                            आधार क्रमांक<sup>*</sup>
                                        </label>
                                        <div className="d-flex">
                                            <input type="text" className="pl-2" />
                                        </div>
                                    </div> */}
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Bank Details of the Beneficiaries/लयभयर्थ्चे बँक तपशील*<sup>*</sup>
                                                    </label>
                                                    <div className=" col-md-12">
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Account Holder's Name /खयतेधयरकयचे नयि
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="pl-2"
                                                                style={{ width: "300px" }}
                                                                name="accHolderName" value={addSeller.accHolderName} onChange={changeHandle}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Account Number/खयते क्रमयांक
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="pl-2"
                                                                style={{ width: "300px" }}
                                                                name="accNo" value={addSeller.accNo} onChange={changeHandle}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Bank Name/बँके चे नयि
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="pl-2"
                                                                style={{ width: "300px" }}
                                                                name="bankName" value={addSeller.bankName} onChange={changeHandle}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            IFSC Code/IFSC कोड
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="pl-2"
                                                                style={{ width: "300px" }}
                                                                name="ifscCode" value={addSeller.ifscCode} onChange={changeHandle}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Branch Name/शयखेचे नयि
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="pl-2"
                                                                style={{ width: "300px" }}
                                                                name="branchName" value={addSeller.branchName} onChange={changeHandle}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label htmlFor="" className="fw-bold mr-2">
                                                            Branch Code/शयखय कोड
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="text"
                                                                className="pl-2"
                                                                style={{ width: "300px" }}
                                                                name="branchCode" value={addSeller.branchCode} onChange={changeHandle}
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभाथ्र्यांचे  बँक तपशील<sup>*</sup>
                                </label>
                                <div className=" col-md-12">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        खातेधारकाचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        खाते क्रमांक
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        बँकेचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        IFSC कोड
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        शाखेचे नाव
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-3">
                                    <label htmlFor="" className="fw-bold mr-2">
                                        शाखा कोड
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            className="pl-2"
                                            style={{ width: "300px" }}
                                        />
                                    </div>
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Please upload Front Page of Bank Passbook/कृ प्य बँक पयसबुकचे पदहले पयन अपलोड करय<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="file" className="pl-2" name="frontOfBankPassBook" onChange={handleImage} />
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    कृपया बँक पासबुकचे पहिले पान अपलोड करा<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Beneficiary's Affidavith/लयभयर्थ्चे प्रदतज्ञयपत्रक<sup>*</sup>
                                                    </label>
                                                    <div className="d-flex">
                                                        <input type="file" className="pl-2" name="affidavith" onChange={handleImage} />
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3">
                                <label htmlFor="" className="fw-bold mr-2">
                                    लाभाथ्र्यांचे प्रतिज्ञापत्रक<sup>*</sup>
                                </label>
                                <div className="d-flex">
                                    <input type="file" className="pl-2" />
                                </div>
                            </li> */}
                                                <li className="mt-3">
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        What Business would you like to do?/तुम्हाला कोणता व्यवसाय करायला आवडेल ?<sup>*</sup>
                                                    </label>
                                                    <div className="col-md-6 border border-2 border-secondary ">
                                                        <div className="mt-2 ">
                                                            <div className="form-check mr-2">
                                                                <input className="form-check-input" type="checkbox" id="flexRadioDefault70" name="foodItem" value={businessLike.foodItem} onChange={(e) => { busiYouLikeToDoChange(e, !businessLike.foodItem) }} />
                                                                <label className="form-check-label" htmlFor="flexRadioDefault70">
                                                                    Food item / खादयपदार्थ{" "}
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" id="flexRadioDefault71" name="minor" value={businessLike.minor} onChange={(e) => { busiYouLikeToDoChange(e, !businessLike.minor) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault71">
                                                                Minor{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" id="flexRadioDefault72" name="independentBus" value={businessLike.independentBus} onChange={(e) => { busiYouLikeToDoChange(e, !businessLike.independentBus) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault72">
                                                                Independent Business
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" id="flexRadioDefault73" name="transport" value={businessLike.transport} onChange={(e) => { busiYouLikeToDoChange(e, !businessLike.transport) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault73">
                                                                Transport business{" "}
                                                            </label>
                                                        </div>
                                                        <div className="form-check mr-2">
                                                            <input className="form-check-input" type="checkbox" id="flexRadioDefault74" name="other" value={businessLike.other} onChange={(e) => { busiYouLikeToDoChange(e, !businessLike.other) }} />
                                                            <label className="form-check-label" htmlFor="flexRadioDefault74">
                                                                Others{" "}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="mt-3">
                                                    <label htmlFor="" className="fw-bold mr-2">
                                                        Declaration<sup>*</sup>
                                                    </label>
                                                    <div className="col-md-12">
                                                        <span style={{ lineHeight: "normal", lineBreak: "auto" }}>
                                                            1. Mr./Mrs./{" "}
                                                            <input
                                                                type="text"
                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="declareName"
                                                                value={addSeller.declareName}
                                                                onChange={changeHandle}
                                                            />
                                                            Son/Daughter/Wife of{" "}
                                                            <input
                                                                type="text"
                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="declareGuard"
                                                                value={addSeller.declareGuard}
                                                                onChange={changeHandle}
                                                            />
                                                            Age{" "}
                                                            <input
                                                                type="text"

                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                    width: "50px",
                                                                }}
                                                                name="declareAge"
                                                                value={addSeller.declareAge}
                                                                onChange={changeHandle}
                                                            />{" "}
                                                            Cast{" "}
                                                            <input
                                                                type="text"

                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="declareCaste"
                                                                value={addSeller.declareCaste}
                                                                onChange={changeHandle}
                                                            />{" "}
                                                            (SC/ST/OBC/NT/SEBC/VINT) ,Resident of{" "}
                                                            <input
                                                                type="text"
                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="residentOf"
                                                                value={addSeller.residentOf}
                                                                onChange={changeHandle}
                                                            />{" "}
                                                            , Post{" "}
                                                            <input
                                                                type="text"
                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="declarePost"
                                                                value={addSeller.declarePost}
                                                                onChange={changeHandle}
                                                            />
                                                            ,Block
                                                            <input
                                                                type="text"
                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="block"
                                                                value={addSeller.block}
                                                                onChange={changeHandle}
                                                            />
                                                            ,District{" "}
                                                            <input
                                                                type="text"
                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="district"
                                                                value={addSeller.district}
                                                                onChange={changeHandle}
                                                            />
                                                            ,State Maharashtra, Pin code{" "}
                                                            <input
                                                                type="text"
                                                                id=""
                                                                style={{
                                                                    borderTop: "none",
                                                                    borderLeft: "none",
                                                                    borderRight: "none",
                                                                    paddingLeft: "5px",
                                                                }}
                                                                name="state"
                                                                value={addSeller.state}
                                                                onChange={changeHandle}
                                                            />
                                                            do hereby declare that, the Information given above and in
                                                            the enclosed documents is true to the best of my biowledge
                                                            and belief, and nothing has been concealed therein. I am
                                                            well aware of the fact that if the information given by me
                                                            is provided false/not true, then I will be liable for
                                                            disqualifying the application as per the law. Also, if any
                                                            benefits availed by me shall be summarily withdrawn. I will
                                                            use the grant/e-vehicle/e-cart for the same purpose as per
                                                            the scheme guidelines dated 10 June 2019
                                                        </span>
                                                    </div>
                                                </li>
                                                {/* <li className="mt-3 d-flex">
                                <label htmlFor="" className="fw-bold mr-2">
                                    Accept (All terms & Condition)<sup>*</sup>
                                </label>
                                <div className="col-md-6 border border-2 border-secondary ">
                                    <div className="d-flex mt-2 mb-2 ">
                                        <input type="checkbox" className="seller-checkbox " />
                                        <label htmlFor="" className="ml-1 seller-label">
                                            Yes
                                        </label>
                                        <input type="checkbox" className="ml-3 seller-checkbox  " />
                                        <label htmlFor="" className="ml-1 seller-label">
                                            No
                                        </label>
                                    </div>


                                </div>
                            </li> */}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="description">
                                        <h6>Appointment of Agency for Providing & Monitoring Self-Employment to Divyang-Jan Through Various Businesses on Environmentally Friendly</h6>
                                        <h4>Manifesto</h4>
                                        <p>I am Mr./Mrs., Son/Daughter/Spouse of Caste Pincode - Post Taluka and Age of -----, State Maharashtra, Village / City_ District_ hereby declare that the information given above and in the attached documents To the best of my knowledge and belief is true and nothing has been concealed therein. I am well aware of the fact that if the information provided by me is false/not true, I will be liable to disqualify the application as per law. Also, any benefits I received will be summarily withdrawn. I will use subsidy/e-vehicle/e-cart for the same purpose as per scheme guidelines dated 10th June 2019</p>
                                        <p>Are the terms and conditions acceptable? *</p>
                                        <p>No</p>
                                        <p>Activities ★</p>
                                        <p>MH. Np</p>
                                        <p>1350</p>
                                        <p>Yes / No</p>
                                    </div>

                                    <div className="text-center mb-3">
                                        <button type="button" onClick={postData} className="text-center seller-btn">Submit</button>
                                    </div>
                                </div>

                                <SpecialSellerPopup
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    successData={successData}
                                    downloadPdf={downloadPdf}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </>
        )
    }
}
export default EnglishSellerSpecial