import '../stylecss/homeStyle.css'
import cmImg from '../assets/CM.jpeg'
import dcmImg from '../assets/DCM.jpeg'
import DcmImage from '../assets/D-CM.jpeg'
import { Button } from '@mui/material'
import { MdAccountCircle } from 'react-icons/md';
import { GiArrowDunk } from 'react-icons/gi';
import LandingNavbar from '../Components/LandingNavbar'
import rikshawimg1 from '../assets/rikshaw1.jpg'
import rikshawimg2 from '../assets/rikshaw2.jpg'
import { useState } from 'react'

import { firebase, auth } from '../FireBase';
import flage from '../../src/assets/logo/flag.webp'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { base_url } from '../server'

const LandingPage = () => {
    const [colorMode, setThemeMode] = useState("black");
    const [bgColorMode, setbgColorMode] = useState("white");
    
    const [fontSize, setFontSize] = useState(18);

    const originalFontSize = () =>{
        setFontSize('20')
    }
    const FontSizeTwenty = () =>{
        setFontSize('22')
    }
    const IncreaseFontSize = () =>{
        setFontSize('26')
    }

    const colorThemeSelector = (color) => {
        var bodyStyle = document.body.style;

        if (color === "green") {
            setThemeMode("white");
            setbgColorMode("#009115");
            bodyStyle.backgroundColor = "#7efd90";
        } else if (color === "orange") {
            setThemeMode("orange");
            setbgColorMode("#ffdc81");
            bodyStyle.backgroundColor = "#ffdc81";
        }
        else {
            setThemeMode("black");
            setbgColorMode("white");
            bodyStyle.backgroundColor = "white";
        }
    };

    const [showsec, setShowSec] = useState(true)
    const anotheShow = () => {
        setShowSec(false)
    }

    const [phone, setPhone] = useState('+91')
    // console.log(phone);


    const [otp, setOtp] = useState('');
    const [final, setfinal] = useState('');

    const navigate = useNavigate()

    // console.log('final', final);

    const [token, setToken] = useState()

    const [validToken, setValidToken] = useState({
        id: token
    })



    const sendOtp = () => {
        window.localStorage.setItem('phoneNumber', phone)
        if (phone === "" || phone.length < 10) return;

        let verify = new firebase.auth.RecaptchaVerifier('recaptcha-container');
        auth.signInWithPhoneNumber(phone, verify).then((result) => {
            setfinal(result);
            // console.log('result', result.verificationId);
            alert("code sent")
            anotheShow()
        })
            .catch((err) => {
                alert(err);
                // window.location.reload()
            });
    }

    const ValidateOtp = async () => {
        if (otp === null || final === null)
            return;
        const res = await final.confirm(otp).then((result) => {
            // console.log('result----', otp, result.user.za);
            setToken(result.user.za)
            postData(result.user.za)
            // navigate('/')

        }).catch((err) => {
            alert("Wrong code");
        })


        // setValidToken()
    }


    const postData = async (toke) => {
        const obj = { id: toke }
        try {
            const res = await axios.post(`${base_url}auth/phone/verify`, obj)
            // console.log('login', res);
            window.localStorage.setItem("token", res.data.token);
            window.localStorage.setItem('isLogin', true)
            // window.localStorage.setItem("user_id", res.data._id);
            navigate('special-seller-sign-up')
            // props.onHide()
        } catch (error) {

        }


    }
    
    return (
        <>
            <LandingNavbar colorMode={colorMode} bgColorMode={bgColorMode} colorThemeSelector={colorThemeSelector} originalFontSize={originalFontSize}  FontSizeTwenty={FontSizeTwenty} IncreaseFontSize={IncreaseFontSize}/>
          
            <div>
                <div>
                    <h3 className='h3-tagg' style={{ textAlign: 'center', fontWeight: 'bold'}}>Self-Employment To Divyangjan Through Various Businesses on Environmentally Friendly E-Vehicles / E-Cart</h3>
                    <h3 className='h3-tagg' style={{ textAlign: 'center', fontWeight: 'bold'}}>पर्यावरणपूरक ई-वाहने/ई-कार्टवर विविध व्यवसायांद्वारे दिव्यांगजनांना स्वयंरोजगार</h3>
                    <div className=' text-center mb-3'>
                        <h4  className='fw-bold m-4' style={{ color: 'black',fontSize: `${fontSize}px`  }}>Click here for Registration <GiArrowDunk style={{ fontSize: '22px', marginLeft: '10px' }} /></h4>
                        <div className='login-section'>
                        {showsec ?
                        <div className="auth-user text-center">
                            {/* <input type="text" placeholder="UDID Authentication Number" />
                            <button type="button" className="" onClick={anotheShow}>Submit UDID</button> */}
                            <p><img src={flage} alt="" style={{ width: '30px' }} /> Note: Please Enter Your Indian Mobile Number</p>
                            <input type="text" placeholder="Please Enter Your Indian Mobile Number" name="phone" value={phone} onChange={(e) => { setPhone(e.target.value) }} />
                            <div id="recaptcha-container"></div>
                            <button type="button" className="" onClick={sendOtp}>Send OTP</button>
                        </div>
                        :
                        <div className="otp-section auth-user text-center">
                            <input type="text" className="mt-3" placeholder="Enter OTP" name="otp" value={otp} onChange={(e) => { setOtp(e.target.value) }} />
                            <button type="button" className="" onClick={ValidateOtp}>Submit OTP</button>
                        </div>
                    }
                    </div>
                        {/* <a href="https://evowcart.abaris.in/special-seller-sign-up">
                            <Button className={`${colorMode === 'black' ? 'purpal' : colorMode === 'white' ? 'geen' : 'orange'}`} variant='contained' sx={{ padding: '8px 30px', ml: '-10px', fontWeight: 'bold', fontSize: `${fontSize}px`, borderRadius: '30px' }}><MdAccountCircle style={{ fontSize: '32px', marginRight: '10px' }} />Register</Button>
                        </a> */}
                    </div>
                </div>

                <div className=" d-flex pb-5 flex-wrap">
                    <div className='imgesdiv d-flex flex-wrap col-sm-6'>
                        <img className='img1' src={rikshawimg1} alt="img" />
                        <img className='img2' src={rikshawimg2} alt="img" />
                    </div>

                    <div className='rightsidebox mt-2 col-sm-6'>
                        <div className={`sideimg-div d-flex  ${colorMode === 'black' ? 'purpal' : colorMode === 'white' ? 'geen' : 'orange'}`}>
                            <img src={cmImg} alt="img" />
                            <div className='content-div'>
                                <h5 style={{ fontWeight: 'bold',fontSize: `${fontSize}px` }}>Shri. Eknath Shinde</h5>
                                <p style={{ fontSize: '14px', marginBottom: '20px' }}>Hon'ble Chief Minister of Maharashtra</p>
                                <h5 style={{ fontWeight: 'bold',fontSize: `${fontSize}px` }}>श्री. एकनाथ शिंदे</h5>
                                <p style={{ fontSize: '14px' }}>माननीय मुख्यमंत्री, महाराष्ट्र राज्य</p>
                            </div>
                        </div>
                        <div className={`sideimg-div d-flex  ${colorMode === 'black' ? 'purpal' : colorMode === 'white' ? 'geen' : 'orange'}`}>
                            <img src={dcmImg} alt="img" />
                            <div className='content-div'>
                                <h5 style={{ fontWeight: 'bold',fontSize: `${fontSize}px` }}>Shri. Devendra Fadnavis</h5>
                                <p style={{ fontSize: '14px', marginBottom: '20px' }}>Hon'ble Deputy Chief Minister of Maharashtra</p>
                                <h5 style={{ fontWeight: 'bold',fontSize: `${fontSize}px` }}>श्री. देवेन्द्र फडणवीस</h5>
                                <p style={{ fontSize: '14px' }}>माननीय उपमुख्यमंत्री, महाराष्ट्र राज्य</p>
                            </div>
                        </div>
                        <div className={`sideimg-div d-flex  ${colorMode === 'black' ? 'purpal' : colorMode === 'white' ? 'geen' : 'orange'}`}>
                            <img src={DcmImage} alt="img" />
                            <div className='content-div'>
                                <h5 style={{ fontWeight: 'bold',fontSize: `${fontSize}px` }}>Shri. Ajit Pawar</h5>
                                <p style={{ fontSize: '14px', marginBottom: '20px' }}>Hon'ble Deputy Chief Minister of Maharashtra</p>
                                <h5 style={{ fontWeight: 'bold',fontSize: `${fontSize}px` }}>श्री. अजित पवार</h5>
                                <p style={{ fontSize: '14px' }}>माननीय उपमुख्यमंत्री, महाराष्ट्र राज्य</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingPage
